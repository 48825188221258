import React from 'react';
//import { Link } from 'react-router-dom';
import calderas from '../images/sala_calderas.jpg';
import suelo from '../images/suelo_radiante.jpg';
import paneles from '../images/paneles.jpg';

const Portfolio = () => {
    return (
        <>
            <div className="my-4 pt-32 pb-4" id='portfolio'>
                <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">experiencia</h2>
                <div className='flex justify-center'>
                    <div className='w-24 border-b-4 border-blue-900 mb-8'></div>
                </div>

                <div className="px-4" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-10">
                        <div className="pb-6 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3 min-h-max">
                            <div className="m-2 text-justify text-sm">
                                <div className="text-justify text-sm flex flex-col items-center justify-center">
                                    <img alt="card img" className="rounded-xl [group-hover:scale-[1.15] transition duration-1000 ease-in-out w-3/4" src={calderas} />
                                </div>
                                <h4 className="font-semibold my-4 mb-10 text-lg md:text-2xl text-center mb-4 h-5">Vivienda unifamiliar, sala de calderas</h4>
                                {/*
                                <p className="text-md font-medium leading-5 h-auto md:h-48">
                                    Our Business Management System is a robust and easily scalable system that streamlines business operations, increase efficiency, and ultimately drive growth and profitability for your business.
                                    With features such as employee management, finance control, and many more modules to effectively manage all aspects of a business.
                                    All data is stored in the cloud and as such it is easily accessible from all devices and from any location.
                                </p>
                                <div className="flex justify-center my-4">
                                    <Link to="/get-demo" className="text-white bg-blue-900 hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-xl">
                                        Schedule Demo
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                </div>
                                */}
                            </div>
                        </div>

                        <div className="pb-6 bg-white transition-all ease-in-out duration-400 overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <div className="text-justify text-sm flex flex-col items-center justify-center">
                                    <img alt="card img" className="rounded-xl [group-hover:scale-[1.15] transition duration-1000 ease-in-out w-3/4" src={suelo} />
                                </div>
                                <h4 className="font-semibold my-4 mb-10 text-lg md:text-2xl text-center mb-4 h-5">Instalación de suelo radiante</h4>
                                {/* <ul className="border-t border-gray-300 py-8 space-y-6">
                                    <li className="flex items-center space-x-2 px-8">
                                        <span className="bg-blue-600 rounded-full p-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3 text-white" viewBox="0 0 20 20" fill="currentColor">
                                                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
                                            </svg>
                                        </span>
                                        <span className="text-gray-600 capitalize">Free Setup Guidance</span>
                                    </li>
                                </ul> */}
                            </div>
                        </div>

                        <div className="pb-6 bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:scale-105 rounded-lg shadow-2xl p-3">
                            <div className="m-2 text-justify text-sm">
                                <div className="text-justify text-sm flex flex-col items-center justify-center">
                                    <img alt="card img" className="rounded-xl [group-hover:scale-[1.15] transition duration-1000 ease-in-out w-3/4" src={paneles} />
                                </div>
                                <h4 className="font-semibold my-4 mb-10 text-lg md:text-2xl text-center mb-4 h-5">Paneles solares</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Portfolio;