import React from 'react';
import roca from '../images/clients/roca_logo.png';
import jacob from '../images/clients/jacob_logo.png';
import bronpi from '../images/clients/bronpi_logo.png';

const clientImage = {
    height: '10rem',
    width: 'auto',
    mixBlendMode: 'colorBurn'
}

const Clients = () => {
    return (
        <div className="mt-8 bg-gray-100">
            <section data-aos="fade-up">
                <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Trabajamos con las mejores marcas</h2>
                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">En Fervisa, tenemos contacto directo con las mejores marcas para ofrecerte la mejor calidad.</h2>
                    </div>

                <div className="p-16" data-aos="fade-in" data-aos-delay="600">
                    <div className="grid sm:grid-cols-3 lg:grid-cols-3">
                        <div style={clientImage} className= "overflow-hidden p-6 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-50 w-1/6 hover:scale-[1.15] transition duration-300 ease-in-out">
                            <a href='https://www.roca.es' className='flex flex-col items-center justify-center'><img src={roca} alt="client" className='w-auto h-full'/></a>
                        </div>

                        <div style={clientImage} className="overflow-hidden p-6 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-50 hover:scale-[1.15] transition duration-300 ease-in-out">
                            <a href='https://www.jacobdelafon.es' className='flex flex-col items-center justify-center'><img src={jacob} alt="client"  className='w-auto h-full'/></a>
                        </div>

                        <div style={clientImage} className="overflow-hidden p-6 flex justify-center transition-all ease-in-out opacity-100 hover:opacity-50 hover:scale-[1.15] transition duration-300 ease-in-out">
                            <a href='http://www.bronpi.com/index.php/es/' className='flex flex-col items-center justify-center'><img src={bronpi} alt="client" className='w-auto h-full'/></a>
                        </div>


                    </div>
                </div>
            </section>
        </div>
    )
}

export default Clients;