import React from 'react';
import Footer from '../components/Footer';
import Intro from '../components/Intro';
import NavBar from '../components/Navbar/NavBar';


const DemoProduct = () => {
    return (
        <>
        <div>
                <NavBar />
        </div>
            <Intro/>
            <Footer/>
        </>

    )
}

export default DemoProduct;
