// PhoneLink.js
import React from 'react';
import phone_logo from '../../images/phone_logo.svg'

const PhoneLink = () => {
  return (
    <div className="phone-link">
      <a href="tel:+34 941 362 978"><img src={phone_logo} alt='phone_logo'></img></a>
    </div>
  );
};

export default PhoneLink;

