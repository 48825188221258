import React from 'react';
import img from '../images/water-tap.svg';
import img2 from '../images/fire.svg';
import img3 from '../images/air-conditioner.svg';
import img4 from '../images/bathtub.svg';

const Services = () => {

    return (
        <div id="services" className="bg-gray-100 pt-32 pb-4">
            <section data-aos="zoom-in-down">
                    <div className="mb-4 pb-4">
                        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">Servicios</h2>

                        <div className='flex justify-center'>
                            <div className='w-24 border-b-4 border-blue-900'></div>
                        </div>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">Estamos altamente comprometidos con ofrecerte el servicio que mejor se adapte a tus necesidades</h2>
                    </div>

                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-5">

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-4 text-justify text-sm flex flex-col items-center justify-center">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out w-3/4" src={img} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Fontanería</h2>
                                    <p className="text-md font-medium">
                                        Tenemos más de 20 años de especialización en instalaciones industriales y domésticas. Trabajamos con personal propio y los mejores materiales, buscando siempre la calidad, la mejora energética y el menor consumo para el cliente.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-4 text-justify text-sm flex flex-col items-center justify-center">
                                <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out w-3/4" src={img2} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Calefacción</h2>
                                    <p className="text-md font-medium">
                                        Nos encargamos de la instalación de sistemas de calefacción, calderas y estufas de gasóleo, leña, pellets y gas para calefacción y agua caliente. Disponemos de una amplia gama de todas ellas, siempre de primeras marcas.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm flex flex-col items-center justify-center">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out w-3/4" src={img3} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Aire acondicionado</h2>
                                    <p className="text-md font-medium">
                                        Contamos con personal que se encargará del montaje, instalación y mantenimiento de su sistema de aire acondicionado.
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                            <div className="m-2 text-justify text-sm flex flex-col items-center justify-center">
                                    <img alt="card img" className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out w-3/4" src={img4} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Complementos de baño</h2>
                                    <p className="text-md font-medium">
                                        Ven a nuestra tienda y podras elegir entre una amplia gama de muebles de baño. Diferentes colores, formas y texturas, para dotar de personalidad a tu espacio.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
            </section>

            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 px-14 justify-between text-center text-sm items-center justify-center" data-aos="zoom-out">
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4 flex flex-col items-center justify-center'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 24 24" className='fill-current'><path d="M2 12h2a7.986 7.986 0 0 1 2.337-5.663 7.91 7.91 0 0 1 2.542-1.71 8.12 8.12 0 0 1 6.13-.041A2.488 2.488 0 0 0 17.5 7C18.886 7 20 5.886 20 4.5S18.886 2 17.5 2c-.689 0-1.312.276-1.763.725-2.431-.973-5.223-.958-7.635.059a9.928 9.928 0 0 0-3.18 2.139 9.92 9.92 0 0 0-2.14 3.179A10.005 10.005 0 0 0 2 12zm17.373 3.122c-.401.952-.977 1.808-1.71 2.541s-1.589 1.309-2.542 1.71a8.12 8.12 0 0 1-6.13.041A2.488 2.488 0 0 0 6.5 17C5.114 17 4 18.114 4 19.5S5.114 22 6.5 22c.689 0 1.312-.276 1.763-.725A9.965 9.965 0 0 0 12 22a9.983 9.983 0 0 0 9.217-6.102A9.992 9.992 0 0 0 22 12h-2a7.993 7.993 0 0 1-.627 3.122z"></path><path d="M12 7.462c-2.502 0-4.538 2.036-4.538 4.538S9.498 16.538 12 16.538s4.538-2.036 4.538-4.538S14.502 7.462 12 7.462zm0 7.076c-1.399 0-2.538-1.139-2.538-2.538S10.601 9.462 12 9.462s2.538 1.139 2.538 2.538-1.139 2.538-2.538 2.538z"></path></svg>
                            </div>
                            <h3 className="text-3xl  text-blue-900 font-bold text-align:center">Nosotros <span className='font-black'>Instalamos</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>
                                    Más de 20 años de experiencia en instalación de elementos de fontanería.
                                </p>
                            </div>
                        </div>
                        <div className="lg:w-1/2 flex flex-col lg:mx-4 justify-center">
                            <div className='text-blue-900 mb-4 flex flex-col items-center justify-center'>
                            <svg version="1.1" id="_x32_" xmlns="http://www.w3.org/2000/svg" width="72" height="72" viewBox="0 0 512 512" fill="#1e3a8a">
<g>
	<path class="st0" d="M360.102,240.012l10.156-10.266c0,0,15.609-13.406,33.406-7.328c30.984,10.578,66.781-0.875,91.609-25.734
		c7.063-7.063,15.641-21.234,15.641-21.234c0.984-1.344,1.328-3.047,0.922-4.672l-1.922-7.906c-0.359-1.484-1.313-2.75-2.625-3.531
		c-1.313-0.766-2.891-0.969-4.344-0.547l-60.984,16.969c-2.266,0.625-4.688-0.219-6.063-2.109l-28.015-38.594
		c-0.859-1.172-1.219-2.641-1.016-4.063l5.641-41c0.297-2.234,1.891-4.047,4.063-4.656l64.406-17.922
		c2.906-0.813,4.672-3.813,3.953-6.766l-2.547-10.359c-0.344-1.469-1.281-2.719-2.563-3.5c0,0-5.047-3.344-8.719-5.234
		c-36.578-18.891-82.64-13.031-113.312,17.656c-22.656,22.656-31.531,53.688-27.375,83.156c3.203,22.656,1.703,34.703-8.078,45.047
		c-0.891,0.922-3.703,3.734-8.047,8L360.102,240.012z"/>
	<path class="st0" d="M211.383,295.418C143.024,361.652,68.461,433.715,68.461,433.715c-2.547,2.438-4,5.797-4.047,9.313
		c-0.047,3.5,1.344,6.891,3.813,9.375l31.938,31.938c2.5,2.484,5.875,3.859,9.391,3.813c3.516-0.031,6.859-1.5,9.281-4.031
		l139.328-140.953L211.383,295.418z"/>
	<path class="st0" d="M501.43,451.371c2.484-2.484,3.859-5.859,3.813-9.375c-0.031-3.516-1.5-6.859-4.031-9.297L227.415,166.246
		l-43.953,43.969L450.805,483.09c2.438,2.547,5.781,4,9.297,4.047s6.891-1.344,9.391-3.828L501.43,451.371z"/>
	<path class="st0" d="M254.196,32.621c-32.969-12.859-86.281-14.719-117.156,16.141c-24.313,24.313-59.875,59.891-59.875,59.891
		c-12.672,12.656-0.906,25.219-10.266,34.563c-9.359,9.359-24.313,0-32.734,8.422L3.29,182.527c-4.391,4.375-4.391,11.5,0,15.891
		l43.016,43.016c4.391,4.391,11.516,4.391,15.906,0l30.875-30.875c8.438-8.422-0.938-23.375,8.438-32.719
		c12.609-12.625,26.375-10.484,34.328-2.547l15.891,15.891l17.219,4.531l43.953-43.953l-5.063-16.688
		c-14.016-14.031-16.016-30.266-7.234-39.047c13.594-13.594,36.047-33.234,57.078-41.656
		C271.102,49.012,267.055,35.668,254.196,32.621z M194.571,103.48c-0.063,0.047,5.859-7.281,5.969-7.375L194.571,103.48z"/>
</g>
</svg>
                            </div>
                            <h3 className="text-3xl  text-blue-900 font-bold">Nosotros <span className='font-black'>Reparamos</span></h3>
                            <div>
                                <p className='my-3 text-xl text-gray-600 font-semibold'>¿Te ha surgido una avería? ¡Llámanos al <a className="text-blue-900 hover:scale-[1.15]" href="tel:+34 941 362 978">941 362 978</a> y estaremos ecantados de ayudarte!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services;